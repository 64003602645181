import { returnPageVariableHash } from './lm_title_description_service';

import config from '@/localconfig';
const urlBase = config.BASE_URL;

let hashOfLMMetaData = {};

const getGeneratorInfo = function (url) {
  return fetch(urlBase + '/api/v1/generators/fetch_by_url?url=' + encodeURIComponent(url));
};

function seo(url) {
  hashOfLMMetaData = {};
  hashOfLMMetaData = returnPageVariableHash(url);

  // Retrive PageTitle and MetaDescription of the page. TODO: Rest Hash variables
  getGeneratorInfo(url)
    .then((response) => response.json())
    .then((data) => {
      if (data.generator) {
        hashOfLMMetaData.pageTitle = data.generator.title;
        hashOfLMMetaData.pageMetaDescription = data.generator.description;
        hashOfLMMetaData.pageMetaContent = data.generator.content;
        hashOfLMMetaData.pageCanonicalUrl = data.generator.canonical;
        hashOfLMMetaData.previous = data.generator.previous;
        hashOfLMMetaData.next = data.generator.next;

        document.title = hashOfLMMetaData.pageTitle;
        const canonicalLink = document.querySelector('link[rel=canonical]');
        const titleMetaTag = document.querySelector('meta[name=title]');
        const descriptionMetaTag = document.querySelector('meta[name=description]');

        if (titleMetaTag) {
          titleMetaTag.setAttribute('content', hashOfLMMetaData.pageTitle);
        }
        if (descriptionMetaTag) {
          descriptionMetaTag.setAttribute('content', hashOfLMMetaData.pageMetaDescription);
        }

        if (canonicalLink) {
          // console.log('tree');
          canonicalLink.setAttribute('href', hashOfLMMetaData.pageCanonicalUrl);
          canonicalLink.setAttribute('content', hashOfLMMetaData.pageCanonicalUrl);
        } else {
          // console.log('treesss');
          const newCanonicalLink = document.createElement('link');
          newCanonicalLink.setAttribute('rel', 'canonical');
          newCanonicalLink.setAttribute('href', hashOfLMMetaData.pageCanonicalUrl);
          newCanonicalLink.setAttribute('content', hashOfLMMetaData.pageCanonicalUrl);
          document.head.appendChild(newCanonicalLink);
        }
        const prevLinkQuery = document.querySelector('link[rel=prev]');
        const nextLinkQuery = document.querySelector('link[rel=next]');
        if (prevLinkQuery) {
          prevLinkQuery.setAttribute('href', hashOfLMMetaData.previous);
        }
        if (nextLinkQuery) {
          nextLinkQuery.setAttribute('href', hashOfLMMetaData.previous);
        }
        const propertUrlQuery = document.querySelector('meta[property="og:url"]');
        if (propertUrlQuery) {
          propertUrlQuery.setAttribute('content', hashOfLMMetaData.pageCanonicalUrl);
        }
        const properTitleQuery = document.querySelector('meta[property="og:title"]');
        if (properTitleQuery) {
          properTitleQuery.setAttribute('content', hashOfLMMetaData.pageCanonicalUrl);
        }
        const properDescriptionQuery = document.querySelector('meta[property="og:description"]');
        if (properDescriptionQuery) {
          properDescriptionQuery.setAttribute('content', hashOfLMMetaData.pageCanonicalUrl);
        }
      }
    })
    .catch((err) => {
      // throw new Error('Cannot set property of null');
      console.log(err.message);
    });
}

// utils/seo.js

export async function fetchSeoData(url) {
  const response = await fetch(urlBase + '/api/v1/generators/fetch_by_url?url=' + encodeURIComponent(url));
  const data = await response.json();
  return data.generator;
}

function generateAlternativeGenderUrl(hashArray) {
  let gender = hashArray[2] === 'boys' ? 'girls' : 'boys';
  const adjustedUrlBase = urlBase.endsWith('/') ? urlBase : urlBase + '/';
  return adjustedUrlBase + hashArray[1] + '/' + gender + '/' + hashArray[3];
}

function addNoFollowAttr(htmlString = '') {
  const allowedDomains = ['https://lifemeasure.com', 'https://lifemeasure-stagging-75c7cd09fba7.herokuapp.com'];

  const isAllowed = (href) => {
    try {
      const url = new URL(href);
      return allowedDomains.some((domain) => url.hostname.includes(domain));
    } catch (error) {
      return false;
    }
  };

  return htmlString.replace(/<a\s+([^>]*href="([^"]+)"[^>]*)>/gi, (match, attributes, href) => {
    if (!isAllowed(href)) {
      if (/rel="nofollow"/i.test(attributes)) {
        return `<a ${attributes}>`;
      } else {
        return `<a ${attributes} rel="nofollow">`;
      }
    }
    return match;
  });
}

export { hashOfLMMetaData, seo, addNoFollowAttr, getGeneratorInfo, generateAlternativeGenderUrl };
