'use client'
import { useEffect, useState } from 'react';
// import ReactGA from 'react-ga';
// import ReactGA4 from 'react-ga4';
import '../styles/globals.css';
import "../styles/loader.scss";
import "../styles/style.scss";
import { Partytown } from "@builder.io/partytown/react";
import Head from 'next/head';

export default function RootLayout({ children }) {
    const [scriptsLoaded, setScriptsLoaded] = useState({
        adThrive: false,
        googleAdSense: false,
        btLoader: false,
        adBlockRecovery: false,
        // googleAnalytic: false,
    });

    // const initGA = () => {
    //     ReactGA.initialize('UA-78124262-1');
    //     ReactGA.pageview(window.location.pathname + window.location.search);
    //     ReactGA4.initialize('G-TK5NGBG6C7');
    //     ReactGA4.send('pageview');
    // };
    const loadScript = (src, scriptKey, type = 'text/javascript', innerHtml = '') => {
        if (!scriptsLoaded[scriptKey]) {
            setScriptsLoaded((prevState) => ({ ...prevState, [scriptKey]: true }));

            if (document.querySelector(`script[src="${src}"]`)) {
                console.log(`${scriptKey} script already loaded.`);
                return;
            }

            const script = document.createElement('script');
            script.src = src;
            script.type = type;
            script.async = true;
            script.defer = true;
            if (innerHtml) {
                script.innerHTML = innerHtml;
            }
            script.onload = () => {
                console.log(`${scriptKey} script loaded useEffect`);
            };
            script.onerror = (e) => {
                console.error(`Failed to load ${scriptKey} script:`, e)
                setScriptsLoaded((prevState) => ({ ...prevState, [scriptKey]: false }));
            };
            document.body.appendChild(script);
        }
    };
    const loadThirdPartLib = () =>{
        if (!scriptsLoaded.adThrive) {
            let adthriveUrl = 'https://ads.adthrive.com/sites/664f856da8539d3d681d9123/ads.min.js?referrer='+ window.encodeURIComponent(window.location.href) + '&cb=' + (Math.floor(Math.random() * 100) + 1)
            loadScript(adthriveUrl, 'adThrive', 'text/javascript');
        }
        if (!scriptsLoaded.googleAdSense) {

            loadScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5281891851945782', 'googleAdSense', 'text/javascript');
        }
        // if (!scriptsLoaded.googleAnalytic) {

        //     initGA()
        // }
        if (!scriptsLoaded.btLoader) {

            loadScript('https://btloader.com/tag?o=5698917485248512&upapi=true&domain=lifemeasure.com', 'btLoader', 'text/javascript');
        }
        if (!scriptsLoaded.adBlockRecovery) {

            loadScript(
                '',
                'adBlockRecovery',
                'text/javascript',
                `!function(){"use strict";var e;e=document,function(){var t,n;function r(){var t=e.createElement("script");t.src="https://cafemedia-com.videoplayerhub.com/galleryplayer.js",e.head.appendChild(t)}function a(){var t=e.cookie.match("(^|[^;]+)\\s*__adblocker\\s*=\\s*([^;]+)");return t&&t.pop()}function c(){clearInterval(n)}return{init:function(){var e;"true"===(t=a())?r():(e=0,n=setInterval((function(){100!==e&&"false"!==t||c(),"true"===t&&(r(),c()),t=a(),e++}),50))}}}().init()}();`
            );
        }
    }
    useEffect(() => {
        console.log("Version 2")
        const handleScroll = () => {
            const scrollPosition = window.scrollY;

            if (scrollPosition > 100) {
                loadThirdPartLib()
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('click', loadThirdPartLib);

        return () =>{
            // Cleanup event listeners if the component unmounts
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', loadThirdPartLib);
        };
    }, [scriptsLoaded]);

    return (
        <html lang="en">
            <Head>
                <Partytown debug={true} logScriptExecution={true}  />
            </Head>
            <body>
                {children}
                {/* Other Scripts could go here, dynamically loaded */}
                {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/highcharts/10.2.1/highcharts.min.js" integrity="sha512-AuSt9Vn4P8B4rNTBOOJGGqI89ktzlGugnty4mZa+dR7koM6sJCJVFwtRiM6lIVmFwHOGIdSCyY1l6UcD78CaBA==" crossOrigin="anonymous" referrerPolicy="no-referrer"></script> */}
            </body>
        </html>
    );
}

